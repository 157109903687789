import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { employeesInitialFilter } from '../../components/tabulator/apply_filters';
import { updateCounter, escapeHtml, blankState } from './utils';

window.Tabulator = Tabulator;

export function employeesTabulator(employees) {
  const translations = JSON.parse(document.getElementById('employees-list').dataset.translations);
  const tabulator = new Tabulator("#employees", {
    height: '100%',
    responsiveLayout: "hide",
    pagination: true,
    paginationSize: 20,
    paginationButtonCount: 1,
    movableColumns: false,
    placeholder: blankState(),
    initialFilter: employeesInitialFilter(),
    data: employees,
    layout: "fitColumns",
    pagination: true,
    paginationSize: 20,
    paginationCounter: function (pageSize, currentRow) {
      const maxNum = currentRow + pageSize - 1;
      return `${currentRow} ${translations.on} ${maxNum < 0 ? 0 : maxNum}`;
    },
    langs: document.querySelector('html').lang,
    columns: [
      { title: translations.employee_id, field: "employee_id", visible: false, download: true }, // for export
      { field: "environment_id", visible: false, download: false }, // for sorting
      { field: "url", visible: false, download: false }, // for sorting
      { field: "destroy_url", visible: false, download: false }, // for sorting
      { title: '', field: 'bulk', width: 16, minWidth: 16, maxWidth: 16, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, download: false },
      { title: '', field: 'photo', width: 64, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false, responsive: 0, download: false },
      { title: translations.employee_name, field: "employee_name", minWidth: 300, widthGrow: 3, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, download: true },
      { title: translations.employee_email, field: "employee_email", visible: false, download: true }, // for export
      { title: translations.employee_external_id, field: "employee_external_id", visible: false, download: true }, // for export
      { title: translations.employee_job_position, field: "job_position", visible: false, download: true }, // for export
      { title: translations.role_name, field: "role_name", minWidth: 150, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, download: true },
      { title: translations.environment_name, field: "environment_name", minWidth: 200, widthGrow: 2, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, visible: displayEnvironmentColumn(), download: displayEnvironmentColumn() },
      { title: translations.organization_name, field: "organization_name", minWidth: 200, widthGrow: 2, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, download: true },
      { title: translations.last_connection_text, field: "last_connection_text", width: 140, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, sorter:function(_a, _b, aRow, bRow, _column, _dir, _sorterParams) {
        return aRow.getData().last_connection - bRow.getData().last_connection
      }, download: false },
      { title: '', field: 'actions', width: 140, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false, responsive: 0, download: false },
      { title: '', field: 'bulk', width: 16, minWidth: 16, maxWidth: 16, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, download: false }
    ],
    rowFormatter:function(row) {
      var data = row.getData();
      var rowEl = row.getElement();
      addClassesFor(rowEl, data)
      setTrackingFor(rowEl, data)
      renderHtmlFor(rowEl, data);
      if (data.role == 'archived') { rowEl.querySelector('[data-invitation').remove() };
    }
  });
  const locale = document.querySelector('html').lang;
  setTimeout(() => {
    tabulator.setLocale(`${locale}-${locale}`);
    updateCounter('employees')
  }, 100);
  blankState();
}

const renderHtmlFor = (row, data) => {
  // the following object is used to render the html for the row
  // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
  // the values are the attributes to be replaced in the html, matching the properties of the data we receive
  const matchingObj = {
    photo: ["image_url", "url"],
    employee_name: ["employee_name", "job_position", "url"],
    role_name: ["role_name", "url"],
    environment_name: ["environment_name", "url"],
    organization_name: ["organization_name", "url"],
    last_connection_text: ["last_connection_text", "url"],
    actions: ["employee_id", "role", "employee_id", "current_sign_in_at", "url", "destroy_url", "employee_name"]
  };

  Object.keys(matchingObj).forEach(template_id => {
    const templateHtml = document.getElementById(template_id).innerHTML;
    let finalHtml = templateHtml;
    matchingObj[template_id].forEach(attribute => {
      finalHtml = finalHtml.replace(`{{${attribute}}}`, escapeHtml(data[attribute]));
    });
    row.querySelector(`[tabulator-field="${template_id}"]`).innerHTML = finalHtml;
  });
}

const displayEnvironmentColumn = () => {
  return document.getElementById('employees-list').dataset.displayEnvironmentColumn == 'true'
}

const addClassesFor = rowEl => {
  rowEl.classList.add('bg-white', 'd-flex', 'pointer', 'bg-primary-100-hover');
};

const setTrackingFor = (rowEl, data) => {
  rowEl.dataset.controller = 'tracking';
  rowEl.dataset.action = "click->tracking#track";
  rowEl.dataset.trackedEvent = "Get to an employee profil page";
  rowEl.dataset.trackedEmployee_id = data.employee_id;
  rowEl.dataset.trackedCurrent_sign_in_at = document.getElementById('employees-list').dataset.trackedCurrent_sign_in_at;
};
